<template>
  <v-row class="d-flex justify-start mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            title="Importar archivo"
            small
            fab
            @click="dialog_subir = true"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :class="$vuetify.breakpoint.xs ? 'mt-0' : ''">
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Marca
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-value="id"
                    item-text="nombre"
                    :items="empresas"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" xl="2" class="py-1">
                  Tipo archivo
                  <v-autocomplete
                    v-model="filtro.tipo"
                    item-value="id"
                    item-text="nombre"
                    :items="ar_tipos.filter(tp => tp.inhabilitado != 1)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" xl="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-value="id"
                    item-text="nombre"
                    :items="ar_estados"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Fecha desde
                  <FechaPicker
                    v-model="filtro.desde"
                    :hideDetails="true"
                    :clearable="true"
                    :left="true"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Fecha hasta
                  <FechaPicker
                    v-model="filtro.hasta"
                    :hideDetails="true"
                    :clearable="true"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Periodo
                  <PeriodoPicker
                    v-model="filtro.periodo"
                    :clearable="true"
                    :left="true"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" xl="1" class="py-1">
                  Archivo id
                  <v-text-field
                    v-model.trim="filtro.id"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="id"
        :headers="headers"
        :items="archivos"
        :loading="load"
        sort-desc
        dense
      >
        <template v-slot:[`item.estado_nombre`]="{ item }">
          <v-chip
            :color="item.color"
            :title="item.error"
            small
          >
            {{ item.estado_nombre }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <BtnConfirmar
            v-if="item.estado == 1"
            icono="fas fa-check-circle"
            clase="mr-2"
            title="Procesar"
            :texto="`¿Desea <strong>procesar</strong> el archivo <strong>${item.nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="procesar(item)"
          />
          <BtnConfirmar
            v-if="item.estado == 1"
            icono="fas fa-trash"
            color="error"
            title="Eliminar"
            :texto="`¿Desea <strong>eliminar</strong> el archivo <strong>${item.nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="eliminar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <ImportarArchivo
      v-model="dialog_subir"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import PeriodoPicker from '../components/util/PeriodoPicker'
import FechaPicker from '../components/util/FechaPicker'
import BtnFiltro from '../components/util/BtnFiltro'
import ImportarArchivo from '../components/ImportarArchivo'
import BtnConfirmar from '../components/util/BtnConfirmar'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      dialog_subir: false,
      headers: [
        { text: 'Id', value: 'id', align: 'right' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Periodo', value: 'periodo' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Marca', value: 'empresa' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Usuario', value: 'usuario' },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Cantidad', value: 'cantidad', align: 'right' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      archivos: [],
      filtro: {
        id: '',
        tipo: null,
        estado: null,
        periodo: null,
        empresa: null,
        desde: null,
        hasta: null
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('archivos/get_tipos')
    await this.$store.dispatch('archivos/get_estados')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState(['empresas']),
    ...mapState('archivos', ['ar_tipos', 'ar_estados'])
  },
  components: {
    PeriodoPicker,
    FechaPicker,
    BtnFiltro,
    ImportarArchivo,
    BtnConfirmar
  },
  methods: {
    async eliminar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('archivos/eliminar', item.id)
        .then((res) => {
          const index = this.archivos.indexOf(item)
          this.archivos.splice(index, 1)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async procesar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('archivos/procesar', item.id)
        .then((res) => {
          item.estado = 2
          const estado = this.ar_estados.find(e => e.id == 2)
          if (estado) {
            item.estado_nombre = estado.nombre
            item.color = estado.color
          }
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      // condiciones: tipo o id o fecha desde
      if (!(this.filtro.tipo || this.filtro.id || this.filtro.desde)) {
        return this.$store.dispatch('show_snackbar', {
          text: `<div>Debe seleccionar al menos uno de los siguientes filtros:</div>
          <div> • Tipo archivo</div>
          <div> • Fecha desde</div>
          <div> • Archivo id</div>`,
          color: 'orange'
        })
      }
      this.archivos = []
      this.$store.state.loading = true
      await this.$store.dispatch('archivos/get_archivos', this.filtro)
        .then((res) => {
          this.archivos = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    limpiar () {
      this.filtro = {
        id: '',
        tipo: null,
        estado: null,
        periodo: null,
        empresa: null,
        desde: null,
        hasta: null
      }
    }
  }
}
</script>