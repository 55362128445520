<template>
  <v-dialog
    v-model="dialog"
    width="650"
  >
    <v-card>
      <v-card-title>
        Importar archivo
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-8">
          <v-col cols="12" sm="6" class="py-0">
            <v-autocomplete
              v-model="tipo"
              label="Tipo"
              item-value="id"
              item-text="nombre"
              :items="ar_tipos.filter(tp => tp.inhabilitado != 1)"
              hide-details
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="3" class="py-0">
            <v-autocomplete
              v-model="empresa"
              label="Marca"
              item-value="id"
              item-text="nombre"
              :items="empresas"
              hide-details
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="3" class="py-sm-0">
            <PeriodoPicker
              v-model="periodo"
              :label="true"
            />
          </v-col>
          <v-col cols="12" class="pb-0">
            <FileDropzone
              :files="archivo"
              :tipos="tipos_validos"
              :multiple="false"
              :max_size="5"
              :disabled="tipo == null || empresa == null"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          nombre="Importar"
          icono="fas fa-upload"
          :texto="`¿Está seguro que desea <strong>importar</strong> el archivo <strong>${archivo.length != 0 ? archivo[0].name : ''}</strong>?`"
          :disabled="archivo.length == 0"
          @action="importar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { getBase64 } from '../util/utils'
import PeriodoPicker from './util/PeriodoPicker'
import BtnConfirmar from './util/BtnConfirmar'
import FileDropzone from './util/FileDropzone'

export default {
  data () {
    return {
      tipo: null,
      empresa: null,
      periodo: moment(new Date()).format('MM/YYYY'),
      archivo: [],
      tipos_validos: ['.xls', '.xlsx', '.ods']
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    ...mapState(['empresas']),
    ...mapState('archivos', ['ar_tipos']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    PeriodoPicker,
    BtnConfirmar,
    FileDropzone
  },
  watch: {
    dialog (val) {
      if (val) {
        this.tipo = null
        this.empresa = null
        this.periodo = moment(new Date()).format('MM/YYYY')
        this.archivo = []
      }
    }
  },
  methods: {
    async importar () {
      this.$store.state.loading = true
      await this.$store.dispatch('archivos/importar', {
        file: await getBase64(this.archivo[0]),
        name: this.archivo[0].name,
        tipo: this.tipo,
        empresa: this.empresa,
        periodo: this.periodo
      })
        .then((res) => {
          this.dialog = false
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    }
  }
}
</script>